<template>
  <drawer-layout
    :title="setTitle"
    :btnLoading="btnLoading"
    :show="open"
    :isForm="isForm"
    permission="system:stage:list:button:edit"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <div class="">
      <info-row :label-width="labelWidth" label="阶段名称">{{ curRow.stageName }}</info-row>
      <info-row :label-width="labelWidth" label="序号">{{ curRow.sequenceNo }}</info-row>
      <info-row :label-width="labelWidth" label="人员职位">{{ curRow.positionName }}</info-row>
      <info-row :label-width="labelWidth" label="翻译系统">{{ curRow.applyTranslation == 1 ? '是' : '否' }}</info-row>
      <info-row :label-width="labelWidth" label="查稿系统">{{ curRow.applyReview == 1 ? '是' : '否' }}</info-row>
      <info-row :label-width="labelWidth" label="可驳回阶段">
        <a-tag v-for="one in curRow.rejectList" :key="one.stageId">
          {{ one.stageName }}
        </a-tag>
      </info-row>
      <info-row :label-width="labelWidth" label="可考核阶段">
        <a-tag v-for="one in curRow.kpiList" :key="one.stageId">
          {{ one.stageName }}
        </a-tag>
      </info-row>
      <info-row :label-width="labelWidth" label="适用部门">
        <a-tag v-for="one in curRow.departments" :key="one.departId">
          {{ one.departName }}
        </a-tag>
      </info-row>
    </div>
    <div slot="form">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="阶段名称" prop="stageName">
          <a-input v-model="formData.stageName" placeholder="请输入阶段名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input-number v-model="formData.sequenceNo" placeholder="请输入序号" class="w-full" />
        </a-form-model-item>
        <a-form-model-item label="人员职位" prop="positionId">
          <a-select placeholder="请选择职位" v-model="formData.positionId">
            <a-select-option v-for="i in positionList" :key="i.id">
              {{ i.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="翻译系统" prop="stageType">
          <a-switch checked-children="开" un-checked-children="关" default-checked v-model="applyTranslation" />
        </a-form-model-item>
        <a-form-model-item label="查稿系统" prop="stageType">
          <a-switch checked-children="开" un-checked-children="关" default-checked v-model="applyReview" />
        </a-form-model-item>
        <a-form-model-item label="可驳回阶段" prop="reversible">
          <a-tree-select
            v-model="rejectList"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreeStageList"
            search-placeholder="选择可驳回阶段"
            placeholder="请选择可驳回阶段"
          />
        </a-form-model-item>
        <a-form-model-item label="可考核阶段" prop="reversible">
          <a-tree-select
            v-model="kpiList"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreeStageList"
            search-placeholder="选择可考核阶段"
            placeholder="请选择可考核阶段"
          />
        </a-form-model-item>
        <a-form-model-item label="选择部门" prop="selectedDepart">
          <a-tree-select
            treeDefaultExpandAll
            v-model="selectedDeparts"
            style="width: 100%"
            tree-checkable
            :tree-data="departList"
            search-placeholder="选择部门"
            placeholder="请选择适用部门"
            @change="changeDepartList"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'

export default {
  mixins: [DrawerMixins],

  data() {
    return {
      defaultUser: [],
      defaultAssUser: [],
      selectedDeparts: [],
      stageTypes: [
        { id: 1, name: '否' },
        { id: 2, name: '是' }
      ],
      rules: {
        stageName: [{ required: true, message: '请输入阶段名称', trigger: 'blur' }]
      },
      formData: {
        departments: [],
        positionId: '',
        sequenceNo: 0,
        stageName: '',
        stageType: 0,
        applyTranslation:0,
        applyReview:0,
      },
      applyTranslation:false,
      applyReview:false,
      url: {
        edit: '/stage/edit',
        add: '/stage/add'
      },
      labelWidth: '80',
      layout: {
        labelCol: {
          style: 'width: 80px',
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      rejectList: [],
      kpiList: []
    }
  },
  methods: {
    initForm() {
      this.formData = {
        departments: [],
        positionId: '',
        sequenceNo: 0,
        stageName: '',
        stageType: 0,
        applyTranslation:0,
        applyReview:0
      }
      this.applyTranslation=false
      this.applyReview=false
      this.selectedDeparts = []
    },

    show() {
      console.log('显示...')
      this.getPosition()
      this.getDepartList()
      this.getStageList()
      if (this.isEdit) {
        this.formData = this.curRow
        const { departments } = this.formData
        this.selectedDeparts = departments?.map(item => item.departId)
      }
      if(this.curRow.applyTranslation){
        this.applyTranslation=true
      }else{
        this.applyTranslation=false
      }
      if(this.curRow.applyReview){
        this.applyReview=true
      }else{
        this.applyReview=false
      }
      this.rejectList = this.curRow.rejectList?.map(item => item.stageId)
      this.kpiList = this.curRow.kpiList?.map(item => item.stageId)
    },

    startEdit() {
      if (this.isEdit) {
        this.formData = this.curRow
        const { departments } = this.formData
        this.selectedDeparts = departments.map(item => item.departId)
      }
    },

    setFormData() {
      this.formData.rejectList = []
      this.formData.kpiList = []
      this.rejectList?.forEach(item => {
        this.formData.rejectList.push({
          stageId: item
        })
      })
      this.kpiList?.forEach(item => {
        this.formData.kpiList.push({
          stageId: item
        })
      })
      if (this.formData.rejectList?.length) {
        this.formData.stageType = 2
      } else {
        this.formData.stageType = 1
      }
      if(this.applyTranslation){
        this.formData.applyTranslation=1
      }else{
        this.formData.applyTranslation=0
      }
      if(this.applyReview){
        this.formData.applyReview=1
      }else{
        this.formData.applyReview=0
      }
    }
  },
  computed: {
    stageTypeModel: {
      get: function() {
        return this.formData.stageType == 2
      },
      set: function(v) {
        if (v) {
          this.formData.stageType = 2
        } else {
          this.formData.stageType = 1
        }
      }
    },
  },
  created() {}
}
</script>

<style lang="stylus"></style>
