var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        btnLoading: _vm.btnLoading,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "system:stage:list:button:edit",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        {},
        [
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "阶段名称" } },
            [_vm._v(_vm._s(_vm.curRow.stageName))]
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.sequenceNo))]
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "人员职位" } },
            [_vm._v(_vm._s(_vm.curRow.positionName))]
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "翻译系统" } },
            [_vm._v(_vm._s(_vm.curRow.applyTranslation == 1 ? "是" : "否"))]
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "查稿系统" } },
            [_vm._v(_vm._s(_vm.curRow.applyReview == 1 ? "是" : "否"))]
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "可驳回阶段" } },
            _vm._l(_vm.curRow.rejectList, function (one) {
              return _c("a-tag", { key: one.stageId }, [
                _vm._v("\n        " + _vm._s(one.stageName) + "\n      "),
              ])
            }),
            1
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "可考核阶段" } },
            _vm._l(_vm.curRow.kpiList, function (one) {
              return _c("a-tag", { key: one.stageId }, [
                _vm._v("\n        " + _vm._s(one.stageName) + "\n      "),
              ])
            }),
            1
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "适用部门" } },
            _vm._l(_vm.curRow.departments, function (one) {
              return _c("a-tag", { key: one.departId }, [
                _vm._v("\n        " + _vm._s(one.departName) + "\n      "),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "阶段名称", prop: "stageName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入阶段名称" },
                    model: {
                      value: _vm.formData.stageName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "stageName", $$v)
                      },
                      expression: "formData.stageName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input-number", {
                    staticClass: "w-full",
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.sequenceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sequenceNo", $$v)
                      },
                      expression: "formData.sequenceNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "人员职位", prop: "positionId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择职位" },
                      model: {
                        value: _vm.formData.positionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "positionId", $$v)
                        },
                        expression: "formData.positionId",
                      },
                    },
                    _vm._l(_vm.positionList, function (i) {
                      return _c("a-select-option", { key: i.id }, [
                        _vm._v(
                          "\n            " + _vm._s(i.name) + "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "翻译系统", prop: "stageType" } },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": "开",
                      "un-checked-children": "关",
                      "default-checked": "",
                    },
                    model: {
                      value: _vm.applyTranslation,
                      callback: function ($$v) {
                        _vm.applyTranslation = $$v
                      },
                      expression: "applyTranslation",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "查稿系统", prop: "stageType" } },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": "开",
                      "un-checked-children": "关",
                      "default-checked": "",
                    },
                    model: {
                      value: _vm.applyReview,
                      callback: function ($$v) {
                        _vm.applyReview = $$v
                      },
                      expression: "applyReview",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "可驳回阶段", prop: "reversible" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "tree-checkable": "",
                      "tree-data": _vm.getTreeStageList,
                      "search-placeholder": "选择可驳回阶段",
                      placeholder: "请选择可驳回阶段",
                    },
                    model: {
                      value: _vm.rejectList,
                      callback: function ($$v) {
                        _vm.rejectList = $$v
                      },
                      expression: "rejectList",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "可考核阶段", prop: "reversible" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "tree-checkable": "",
                      "tree-data": _vm.getTreeStageList,
                      "search-placeholder": "选择可考核阶段",
                      placeholder: "请选择可考核阶段",
                    },
                    model: {
                      value: _vm.kpiList,
                      callback: function ($$v) {
                        _vm.kpiList = $$v
                      },
                      expression: "kpiList",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择部门", prop: "selectedDepart" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      treeDefaultExpandAll: "",
                      "tree-checkable": "",
                      "tree-data": _vm.departList,
                      "search-placeholder": "选择部门",
                      placeholder: "请选择适用部门",
                    },
                    on: { change: _vm.changeDepartList },
                    model: {
                      value: _vm.selectedDeparts,
                      callback: function ($$v) {
                        _vm.selectedDeparts = $$v
                      },
                      expression: "selectedDeparts",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }